import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo, FAQJsonLd  } from 'gatsby-plugin-next-seo';
export default ({ data }) => {
  const { page } = data
  const { title, content, databaseId, seo, slug } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.pelipaikat.fi/${slug}/`}
      />
      {title === "Vedonlyöntisivustot" &&
        <FAQJsonLd
        questions={[
          { question: 'Ovatko voitot verovapaita vedonlyöntisivuilla?', answer: 'Melkein aina. Verovapaat voitot pääset tarkastamaan hyvin helposti vedonlyöntisivun lisenssistä. Jos vedonlyöntisivut operoivat ETA-maan pelilisenssillä, ovat voitot verovapaat.' },
          { question: 'Mistä löydän parhaat vedonlyöntisivut?', answer: 'Täältä tietenkin! Olemme etsineet netistä kaikki vedonlyöntisivut, jotka ovat todella hyviä valintoja suomalaisille pelaajille. Tulet siis löytämään sivujemme kautta parhaat sivut vedonlyöntiä varten.' },
          { question: 'Mitä vedonlyöntisivut tarjoavat?', answer: 'Netissä toimivat vedonlyöntisivut tarjoavat muun muassa kilpailulliset kertoimet sekä kattavasti kohteita. Voit myös napata talteen hyvät uusien pelaajien edut sivujemme kautta!' },
          { question: 'Kuinka tunnistan hyvät vedonlyöntisivut?', answer: 'Hyvät vedonlyöntisivut voi tunnistaa useasta eri asiasta. Kannattaa ihan ensimmäiseksi tarkastaa, että pelisivut ovat lisensoitu. Hyvät bookkerit tarjoavat tietenkin aina suomeksi käännetyt verkkosivut ja laadukasta asiakaspalvelua.' },
          { question: 'Onko vedonlyöntisivut tarkoitettu suomalaisille pelaajille?', answer: 'Kyllä! Netti on täynnä suomalaisille pelaajille suunnattuja vedonlyöntisivuja. Voit tarkastaa sivujemme kautta kaikki luotettavat sekä turvalliset vedonlyöntisivut, jotka ottavat suomalaisia pelaajia vastaan.' },
        ]}
      />
      }
      <article
        className={`post-${databaseId} post page type-page status-publish hentry testi`}
        id={`post-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1
              className="entry-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        </header>
        <div className="post-inner thin container">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      seo {
        title
        metaDesc
      }
      slug
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
